import { Avatar, Col, Steps } from "antd";
import InputInlineApi from "app/components/Form/InputInlineApi";
import { ShopType } from "app/models/ShopType";
import { configSelector } from "app/redux/slides/config.slide";
import thicknessServices from "app/services/thickness.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CalculationBilling from "../../components/Part/CalculationBilling";
import CalculationDone from "../../components/Part/CalculationDone";
import CalculationStep1 from "../../components/Part/CalculationStep1";
import CalculationStep2 from "../../components/Part/CalculationStep2";
import CalculationStep3 from "../../components/Part/CalculationStep3";
import { CuttingType } from "../../models/CuttingType";
import { Project, ProjectStatus } from "../../models/Project";
import { UserRole } from "../../models/UserRole";
import { authSelector } from "../../redux/slides/auth.slide";
import { loadingActions } from "../../redux/slides/loading.slide";
import { langSelector } from "../../redux/slides/locale.slide";
import { materialActions } from "../../redux/slides/material.slide";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import materialServices from "../../services/material.service";
import projectServices from "../../services/project.service";
import "./calculation.module.scss";
import Sticky from "react-stickynode";

export function CalculationPartsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const lang = useSelector(langSelector);
  const config = useSelector(configSelector);
  const project: Project = useSelector(projectSelector).data;
  // const [project, setProject] = useState<Project>();
  const [latestStep, setLatestStep] = useState(0);

  const [current, setCurrent] = useState(0);
  const [cuttingType, setCuttingType] = useState(
    config.shopType === ShopType.BOTH ||
      config.shopType === ShopType.MILLING_AND_TURNING
      ? CuttingType.MILLING_AND_TURNING
      : CuttingType.SHEET_METAL
  );
  const [cadFiles, setCadFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const dataSteps = [
    {
      title: t("step.default.1.title"),
    },
    {
      title: t("step.default.2.title"),
    },
    ...(auth.user.role === UserRole.BUYER
      ? [
          {
            title: t("step.default.3.title"),
          },
        ]
      : []),
    {
      title: project?.selfCalculation
        ? t("step.self.5.title")
        : t("step.default.5.title"),
    },
    {
      title: t("completed"),
    },
  ];

  const [steps, setSteps] = useState<any[]>(dataSteps);

  const initMillingAndTurining = async () => {
    try {
      const millingAndTurining = await materialServices.getPartMaterialGroups(
        CuttingType.MILLING_AND_TURNING
      );
      dispatch(materialActions.setGroups(millingAndTurining));
    } catch (error) {}
  };
  const initSheetMetal = async () => {
    try {
      const sheetMetal = await materialServices.getPartMaterialGroups(
        CuttingType.SHEET_METAL
      );
      dispatch(materialActions.setCuttingMachineGroups(sheetMetal));
    } catch (error) {}
  };
  const initThickness = async () => {
    try {
      const thicknesses = await thicknessServices.findAllValues();
      dispatch(materialActions.setThicknesses(thicknesses));
    } catch (error) {}
  };
  const getMaterialGroups = async () => {
    initMillingAndTurining();
    initSheetMetal();
    initThickness();
  };

  const getProject = async () => {
    try {
      const rs: Project = await projectServices.detail(projectId);
      dispatch(projectActions.setProject(rs));
      dispatch(loadingActions.show(false));
      setLoading(false);
      if (rs.status !== ProjectStatus.IN_PROGRESS) {
        setCurrent(dataSteps.length - 1);
        return;
      }
      dispatch(projectActions.setDeliveryCost(null));
      dispatch(projectActions.setDeliveryOption(rs.deliveryOption));
      if (rs.parts && rs.parts.length > 0) {
        const allCalculated =
          rs.parts.filter(
            (p) => (p.auto && p.totalPrice) || (!p.auto && p.material)
          ).length === rs.parts.length;
        if (allCalculated) setCurrent(rs?.selfCalculation ? 1 : 2);
        else setCurrent(1);
      }
    } catch (error) {
      navigate(`/${auth.user.role}/projects`);
    }
  };

  const initData = async () => {
    dispatch(loadingActions.show(true));
    getProject();
    getMaterialGroups();
  };

  useEffect(() => {
    setCurrent(0);
    setLatestStep(0);
    initData();
    return () => {
      dispatch(projectActions.setProject(null));
      dispatch(projectActions.setDeliveryCost(null));
      dispatch(projectActions.setDeliveryOption(null));
    };
  }, [projectId]);
  useEffect(() => {
    if (current > latestStep) {
      setLatestStep(current);
    }
    if (current === 0) {
      setCuttingType(
        config.shopType === ShopType.BOTH ||
          config.shopType === ShopType.MILLING_AND_TURNING
          ? CuttingType.MILLING_AND_TURNING
          : CuttingType.SHEET_METAL
      );
    }
  }, [current]);
  useEffect(() => {
    if (project && project.parts?.length === 0) {
      setLatestStep(0);
      setCurrent(0);
    }
  }, [project]);
  useEffect(() => {
    if (!project) return;
    if (project?.status === ProjectStatus.REQUESTED_OFFER) {
      navigate(`/${auth.user.role}/projects/${projectId}`);
      return;
    }

    let s = [...dataSteps];
    if (project.manual) {
      s?.splice(3, 2);
    }

    s.map((item: any, index: number) => {
      item.title = s[index].title;
      if (!item.icon) {
        item.icon = <Avatar icon={`${index + 1}`} />;
      }
      // delete item.status;
      if (project.parts?.length === 0 && index > 0) {
        item.disabled = true;
        return false;
      }
      if (
        (project?.blocked && index === 1) ||
        latestStep === steps.length - 1
      ) {
        item.disabled = true;
        return false;
      }
      if (index !== 0 && index <= latestStep) {
        item.disabled = false;
        if (index === latestStep) {
          // item.status = "process";
          // item.icon = <LoadingOutlined />;
        }
      } else {
        item.disabled = true;
      }
      return false;
    });
    setSteps([...s]);
  }, [current, latestStep, projectId, lang, project]);

  const onFileUpload = (rs: any) => {
    setCadFiles(rs.files);
    setCurrent(current + 1);
  };

  const onCuttingTypeChanged = (v: CuttingType) => {
    setCuttingType(v);
  };

  // const canNext = () => {
  //   const i = project?.parts?.findIndex((p) => p.auto && !p.totalPrice);
  //   return i === -1;
  // };

  const onPrevStep = () => {
    if (current === 1) return;
    setCurrent(current - 1);
  };
  const onNextStep = () => {
    setLatestStep(current + 1);
    setCurrent(current + 1);
  };

  const onStepChange = (value: number) => {
    setCurrent(value);
  };

  const onFilesLoaded = () => {
    setCadFiles([]);
  };

  const onAddFiles = () => {
    setLatestStep(1);
  };

  const moveToNextStep = async () => {
    await getProject();
    setCadFiles([]);
    onNextStep();
  };

  const handleChangeProjectName = async (name: string) => {
    const rs = await projectServices.updateName(project.id, name);
    return rs.name;
  };

  if (!project) return <></>;

  return (
    <>
      <Col xs={24} sm={12}>
        <h5>
          <InputInlineApi
            callBack={handleChangeProjectName}
            value={project?.name}
            name="projectName"
            label={t("project.table.name")}
          />
        </h5>
      </Col>
      <div className="part-calculation-steps-wrapper">
        <Steps
          type="navigation"
          className="site-navigation-steps part-calculation-steps"
          current={current}
          items={steps}
          onChange={onStepChange}
        />
      </div>
      {!loading && (
        <div className="mt-3">
          {current === 0 && (
            <CalculationStep1
              onChange={onFileUpload}
              onCuttingTypeChange={onCuttingTypeChanged}
              onGenerateDxf={moveToNextStep}
            />
          )}
          {current === 1 && project && (
            <CalculationStep2
              project={project}
              cuttingType={cuttingType}
              files={cadFiles}
              onFilesLoaded={onFilesLoaded}
              onAddFiles={onAddFiles}
              onNext={onNextStep}
            />
          )}
          {auth.user.role === UserRole.BUYER && (
            <>{current === 2 && <CalculationStep3 onCheckOut={onNextStep} />}</>
          )}
          {/* {auth.user.role === UserRole.SELLER && (
          <>{current === 2 && <PartsAdapt onSubmit={onNextStep} />}</>
        )} */}
          {auth.user.role === UserRole.BUYER && current === 3 && (
            <CalculationBilling onSubmit={onNextStep} />
          )}
          {auth.user.role === UserRole.SELLER && current === 2 && (
            <CalculationBilling onSubmit={onNextStep} />
          )}
          {((auth.user.role === UserRole.BUYER && current === 4) ||
            (auth.user.role === UserRole.SELLER && current === 3)) && (
            <CalculationDone project={project} />
          )}
        </div>
      )}
    </>
  );
}
