import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/User";

const initialState: { profile: User | null } = { profile: null };
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const userActions = userSlice.actions;
export const profileSelector = (state: any) => state.user.profile;
export default userSlice.reducer;
