import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { images } from "../../../assets/images";
import { authActions, authSelector } from "../../redux/slides/auth.slide";
import styles from "./auth.module.scss";
import LocaleButton from "../Locale/LocaleButton";

function AuthDefault() {
  return (
    <div className={styles.auth}>
      <div className={styles.bgLayer}>
        <object data="/images/bg-main.svg" type="image/svg+xml"></object>
      </div>
      <div className="box-locale">
        <LocaleButton />
      </div>
      <div className="row justify-content-center">
        <div className="col col-12 col-md-5 col-lg-5 "></div>
        <div className="col col-12 col-md-7 col-lg-5 ">
          <div className="d-flex flex-column align-items-center align-items-md-center">
            <Link to={"/"}>
              <img src="/images/logo.png" className={styles.logo} />
            </Link>
            <div className="row mt-5 w-100">
              <div className="col col-11 col-md-10 mx-auto ms-md-auto">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthDefault;
