import { Badge } from "antd";
import { useSelector } from "react-redux";
import { HTMLAttributes } from "react";
import { ProjectStatus } from "../../models/Project";
import { unreadSelector } from "../../redux/slides/unread.slide";
interface DefaultProps extends HTMLAttributes<any> {
  mode: "GROUP1" | "GROUP2" | "GROUP3" | "GROUP4" | "BUYER_GROUP2_SUB1" | "BUYER_GROUP2_SUB2";
}

function CountUnreadProjects(props: DefaultProps) {
  const { mode } = props;
  const uncount = useSelector(unreadSelector);

  return (
    <>
      {uncount.data && uncount.data[mode] > 0 && (
        <Badge
          className="ms-auto me-1"
          count={uncount.data[mode] || 0}
          color="#fff"
          style={{ color: "#000" }}
        />
      )}
    </>
  );
}
CountUnreadProjects.STATUS_KEYS = {
  SELLER_GROUP1: [ProjectStatus.CHECKOUTED, ProjectStatus.REQUESTED_OFFER],
  SELLER_GROUP2: [ProjectStatus.PRICE_ADAPTED],
  BUYER_GROUP2_SUB1: [ProjectStatus.CHECKOUTED, ProjectStatus.REQUESTED_OFFER],
  BUYER_GROUP2_SUB2: [ProjectStatus.PRICE_ADAPTED],
  GROUP3: [ProjectStatus.SELLER_APPROVED, ProjectStatus.BUYER_APPROVED],
  GROUP4: [ProjectStatus.SELLER_REJECTED, ProjectStatus.BUYER_REJECTED],
};

export default CountUnreadProjects;
