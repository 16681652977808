import {
  ExportOutlined,
  LinkOutlined,
  LoadingOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Select, Space, notification } from "antd";
import { CuttingType } from "app/models/CuttingType";
import { Material } from "app/models/Material";
import { MaterialGroup } from "app/models/MaterialGroup";
import { Part } from "app/models/Part";
import { PartViewMode } from "app/models/PartViewMode";
import { TechDrawingData } from "app/models/TechDrawingData";
import { configSelector } from "app/redux/slides/config.slide";
import { materialSelector } from "app/redux/slides/material.slide";
import { profileSelector, userActions } from "app/redux/slides/user.slide";
import materialServices from "app/services/material.service";
import userServices from "app/services/user.service";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonSelect from "../Button/ButtonSelect";
import LocalizationName from "../Locale/LocalizationName";
import MaterialNumber from "../Material/MaterialNumber";
import RobotCalc from "./RobotCalc";
import { Compliance } from "app/models/Compliance";
const { Option } = Select;

interface DefaultProps extends HTMLAttributes<any> {
  mode?: PartViewMode;
  cuttingType: CuttingType;
  dataPart: any;
  requestingTechDrawingData?: any;
  techDrawingData?: TechDrawingData;
  onMaterialSelectedCallBack: any;
  onSetMaterialbyRobotCallBack?: any;
  part?: Part;
  materialProp?: any;
  thickness?: any;
}

const renderPriceSymbol = (price: number) => {
  const p = Math.floor(price);
  switch (p) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return (
        <>
          <b>€</b>€€€
        </>
      );
    case 6:
    case 7:
    case 8:
      return (
        <>
          <b>€€</b>€€
        </>
      );
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
      return (
        <>
          <b>€€€</b>€
        </>
      );
  }
  return (
    <>
      <b>€€€€</b>
    </>
  );
};

function MaterialSelectModal(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const {
    cuttingType,
    onMaterialSelectedCallBack,
    requestingTechDrawingData,
    techDrawingData,
    part,
    thickness,
  } = props;
  const dispatch = useDispatch();
  const isCuttingTypeSheetMetalStep =
    cuttingType === CuttingType.SHEET_METAL_STP;
  const config = useSelector(configSelector);
  const profile = useSelector(profileSelector);
  const [groupTabSeleted, setGroupTabSeleted] = useState<MaterialGroup>();
  const [materials, setMaterials] = useState<Material[]>([]);
  const [openMaterial, setOpenMaterial] = useState(false);
  const [search, setSearch] = useState("");
  const [thicknessData, setThicknessData] = useState<any>(
    thickness || part?.thickness
  );
  const [checkFavourite, setCheckFavourite] = useState(false);
  const [sortBy, setSortBy] = useState<any>();
  const [checkThickness, setCheckThickness] = useState(!!thicknessData);
  const [filterMaterials, setFilterMaterials] = useState<Material[]>();
  const [filterMaterialGroups, setFilterMaterialGroups] =
    useState<MaterialGroup[]>();
  const { groups, cuttingMachineGroups, thicknesses, compliances } =
    useSelector(materialSelector);
  const [material, setMaterial] = useState<Material | undefined>(
    part?.material
  );
  const [materialGroup, setMaterialGroup] = useState(
    !!part
      ? groups?.find((x: MaterialGroup) => x.id === part?.materialGroupId) ||
          undefined
      : undefined
  );
  const [thicknessesSelected, setThicknessesSelected] = useState<any[]>(thicknessData ? [thicknessData] : []);
  const [groupSelected, setGroupSelected] = useState<any[]>([]);
  const [compliancesData, setCompliancesData] = useState<Compliance[]>([])
  const [complianceSelected, setComplianceSelected] = useState<any[]>([])
  const { t } = useTranslation();

  const isCuttingTypeSheetMetalDxfDwg =
    cuttingType === CuttingType.SHEET_METAL_DXF ||
    cuttingType === CuttingType.SHEET_METAL_DWG;
  const materialGroups: MaterialGroup[] =
    (cuttingType === CuttingType.MILLING_AND_TURNING
      ? groups.filter((g: any) => g.materials && g.materials.length > 0)
      : cuttingMachineGroups
          .filter((g: any) => g.materials && g.materials.length > 0)
          .map((g: MaterialGroup) => {
            const group = { ...g };
            group.materials =
              g.materials?.filter(
                (m) => m.thicknesses && m.thicknesses.length
              ) || [];
            return group;
          })) || [];

  const showModal = () => {
    setOpenMaterial(true);
  };

  const hideModal = () => {
    setOpenMaterial(false);
  };

  const onMaterialGroupClicked = (groupId?: any) => {
    if (groupSelected.includes(groupId)) {
      setGroupSelected(groupSelected.filter((g) => g !== groupId));
    } else {
      setGroupSelected([...groupSelected, groupId]);
    }
  };
  const onThicknessClicked = (thicknessId?: any) => {
    if (isCuttingTypeSheetMetalStep && !!part?.thickness) return;
    if (thicknessesSelected.includes(thicknessId)) {
      setThicknessesSelected(
        thicknessesSelected.filter((t) => t !== thicknessId)
      );
    } else {
      setThicknessesSelected([...thicknessesSelected, thicknessId]);
    }
  };
  const onComplianceClicked = (id?: any) => {
    if (complianceSelected.includes(id)) {
      setComplianceSelected(complianceSelected.filter((g) => g !== id));
    } else {
      setComplianceSelected([...complianceSelected, id]);
    }
  };

  const onSearchChanged = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!!materialGroups) {
      let materials: Material[] = [];
      materialGroups.map((g) => {
        const group: any = { ...g };
        const m = group.materials?.map((m: any) => {
          const material = {
            ...m,
            group: {
              id: group.id,
              localizations: group.localizations,
            },
          };
          return material;
        });
        materials = [...materials, ...m];
      });
      setMaterials(materials);
    }
  }, []);
  useEffect(() => {
    if(compliances.length > 0) {
      const data = [...compliances];
      data.sort((a: Compliance, b: Compliance) => {
        const nameA: any = a.name?.toUpperCase();
        const nameB: any = b.name?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setCompliancesData([...data]);
    }
  }, [compliances])
  useEffect(() => {
    if (material) {
      const g = materialGroups.find((g) =>
        g.materials?.find((m) => m.id === material?.id)
      );
      setMaterialGroup(g);
    }
  }, [material]);

  useEffect(() => {
    if (materials) {
      const filterMaterials = materials.filter(
        (m) =>
          (!groupSelected.length || groupSelected.includes(m.group.id)) &&
          (!search ||
            `${m.name}${m.number}`.toLowerCase().indexOf(search.toLowerCase()) >
              -1) &&
          (isCuttingTypeSheetMetalStep ||
            !thicknessesSelected.length ||
            (m.thicknesses &&
              m.thicknesses.findIndex((t) =>
                thicknessesSelected.includes(t.value)
              ) > -1)   
          ) && (!complianceSelected.length ||
            (m.compliances &&
              m.compliances.findIndex((t) =>
              complianceSelected.includes(t.id)
              ) > -1))
      );
      if(sortBy) {
        filterMaterials.sort((a: any, b: any) => {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        })
        setFilterMaterials(filterMaterials);
      } else {
        setFilterMaterials(filterMaterials);
      }
      setFilterMaterialGroups(groups);
    } else {
      setFilterMaterials(undefined);
      setFilterMaterialGroups(undefined);
    }
  }, [search, thicknessData, thicknessesSelected, groupSelected, complianceSelected, sortBy]);

  useEffect(() => {
    setThicknessData(thickness || part?.thickness);
    setThicknessesSelected((thickness || part?.thickness) ? [thickness || part?.thickness] : []);
  }, [part?.thickness, thickness]);

  const onMaterialSelected = (data: Material) => {
    setMaterial(data);
    if (isCuttingTypeSheetMetalDxfDwg) {
      onMaterialSelectedCallBack(
        data,
        thicknessesSelected.length == 1 ? thicknessesSelected[0] : undefined
      );
    } else {
      onMaterialSelectedCallBack(data);
    }
    hideModal();
  };

  const getMaterial = async (id: number) => {
    try {
      const rs: Material = await materialServices.detail(id);
      const group = materialGroups?.find((m) => m.id === rs.groupId);
      onMaterialSelected(rs);
      setMaterialGroup(group);
    } catch (error) {}
  };

  const onSetMaterialbyRobot = () => {
    if (techDrawingData?.materialId) getMaterial(techDrawingData?.materialId);
  };

  useEffect(() => {
    if (techDrawingData && techDrawingData.materialId) {
      getMaterial(techDrawingData.materialId);
    }
  }, [techDrawingData]);

  const onSortByChanged = (value: any) => {
    setSortBy(value)
  }

  const OptionMaterial = (props: { material: Material }) => {
    const m = props.material;
    const active = m.id === material?.id;
    const onMaterialClick = () => {
      onMaterialSelected(m);
    };
    const addFavourite = async () => {
      try {
        let rs;
        if(!profile.favouriteMaterialIds.includes(m.id)) {
          rs = await userServices.addFavourite(m.id);
        } else {
          rs = await userServices.removeFavourite(m.id);
        }
        dispatch(userActions.setProfile(rs));
      } catch (error) {}
    };
    if(checkFavourite && !profile.favouriteMaterialIds.includes(m.id)) return <></>;
    return (
      <div
        className={`row material-item${active ? " active" : ""}`}
        key={`material-${m.id}`}
      >
        <div className="col-auto btn-favourite" onClick={addFavourite}>
          {profile.favouriteMaterialIds.includes(m.id) ? <StarFilled/> : <StarOutlined/>}
        </div>
        <div className="col col-4" onClick={onMaterialClick}>
          <LocalizationName localizations={m.group?.localizations} />
        </div>
        <div className="col col-4" onClick={onMaterialClick}>
          {m.name}{m.link && <>
          &nbsp;<a href={m.link} target="_blank" rel="noreferrer"><i className="bi bi-box-arrow-up-right"></i></a>
        </>}
        </div>
        {!process.env.REACT_APP_TECHNICAL_PLASTICS && <>
          <div className="col" onClick={onMaterialClick}>
            <MaterialNumber value={m.number} />
          </div>
          <div className="col-auto text-center" onClick={onMaterialClick}>
            {renderPriceSymbol(m.pricePerKilo)}
          </div>
        </>}
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <div className="d-flex align-items-center">
        {(cuttingType !== CuttingType.SHEET_METAL_STP || part) && (
          <ButtonSelect
            style={{ width: "100%" }}
            onClick={showModal}
            tooltip={
              material ? (
                <>
                  {material?.name} (
                  <LocalizationName
                    localizations={materialGroup?.localizations}
                    valueDefault={materialGroup?.name}
                  />
                  )
                </>
              ) : undefined
            }
          >
            {material ? (
              <>
                {material?.name} (
                <LocalizationName
                  localizations={materialGroup?.localizations}
                  valueDefault={materialGroup?.name}
                />
                )
              </>
            ) : (
              t("pleaseSelect")
            )}
          </ButtonSelect>
        )}
        {cuttingType === CuttingType.SHEET_METAL_STP && !part && (
          <LoadingOutlined />
        )}
        {material?.link && <>
          &nbsp;<a href={material.link} target="_blank" rel="noreferrer"><i className="bi bi-box-arrow-up-right"></i></a>
        </>}
        &nbsp;
        {requestingTechDrawingData || !!techDrawingData?.materialId ? (
          <RobotCalc
            className="pointer"
            loading={requestingTechDrawingData}
            completed={
              !requestingTechDrawingData &&
              techDrawingData?.materialId === material?.id
            }
            onClick={onSetMaterialbyRobot}
            message={t("part.robot.material.message")}
          />
        ) : null}
      </div>
      <Modal
        className="selectMaterial"
        open={openMaterial}
        onCancel={hideModal}
        footer={null}
        centered
        destroyOnClose
      >
        <div className="row wrapper-materials">
          <div className="col col-3 col-md-4 group">
            <Space
              direction="vertical"
              className="group-menu px-3 mb-1"
              style={{ width: "100%" }}
              size={0}
            >
              <p className="mb-2 sub-l">
                <strong>{t("material")}</strong>
              </p>
              <div className="">
                <div className="filter-group d-flex flex-wrap gap-2">
                  {materialGroups
                    ?.filter((m) => m.materials && m.materials.length > 0)
                    .map((item) => (
                      <Button
                        key={`group-${item.id}`}
                        type={
                          groupSelected.includes(item?.id)
                            ? "primary"
                            : "default"
                        }
                        onClick={onMaterialGroupClicked.bind(null, item.id)}
                      >
                        {/* {`${groupSelected.includes(materialGroup?.id)}`} */}
                        <LocalizationName
                          localizations={item?.localizations}
                          valueDefault={item.name}
                        />
                      </Button>
                    ))}
                </div>
              </div>
              {cuttingType !== CuttingType.MILLING_AND_TURNING && (
                <>
                  <p className="mb-2 mt-2 sub-l">
                    <strong>{t("part.form.thickness")}</strong>
                  </p>
                  <div className="filter-thickness d-flex flex-wrap gap-2">
                    {thicknesses.map((t: any) => (
                      <Button
                        key={`thickness-option-${t}`}
                        type={
                          thicknessesSelected.includes(t)
                            ? "primary"
                            : "default"
                        }
                        disabled={
                          isCuttingTypeSheetMetalStep &&
                          thicknessData &&
                          !thicknessesSelected.includes(t)
                        }
                        onClick={onThicknessClicked.bind(null, t)}
                      >
                        {t} mm
                      </Button>
                    ))}
                  </div>
                </>
              )}
              {!!compliancesData?.length && 
                <div>
                  <p className="mt-2 sub-l">
                    <strong>{t("part.confirmities")}</strong>
                  </p>
                  <div className="filter-group d-flex flex-wrap gap-2">
                    {compliancesData.map((item: Compliance) => (
                        <Button
                          key={`compliance-${item.id}`}
                          type={
                            complianceSelected.includes(item?.id)
                              ? "primary"
                              : "default"
                          }
                          onClick={onComplianceClicked.bind(null, item.id)}
                        >
                          {item.name}
                        </Button>
                      ))}
                  </div>
                </div>
              }
            </Space>
          </div>
          <div className="col col-md p-0 materials">
            <div className="wrapper-filters b-shadow app-form">
              <Input
                placeholder="Search material"
                prefix={<SearchOutlined />}
                allowClear
                onChange={onSearchChanged}
              />
              <Button
                className="d-flex align-items-center"
                icon={<StarFilled />}
                // style={{ width: "12rem" }}
                type={checkFavourite ? "primary" : "default"}
                onClick={setCheckFavourite.bind(null, !checkFavourite)}
              >
                Favorites
              </Button>
              <Select allowClear placeholder="Sort by" style={{width: '15rem'}} onChange={onSortByChanged}>
                <Option value="name">Sort by name</Option>
                <Option value="pricePerKilo">Sort by price</Option>
              </Select>
            </div>
            <div className="material-list">
              {(filterMaterials || materials).map((item) => (
                <OptionMaterial material={item} key={`material-${item.id}`} />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MaterialSelectModal;
