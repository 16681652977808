import {
  CheckOutlined,
  CloseOutlined,
  SendOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Project, ProjectStatus } from "../../models/Project";
import { UserRole } from "../../models/UserRole";
import { authSelector } from "../../redux/slides/auth.slide";
import { projectActions } from "../../redux/slides/project.slide";
import projectServices from "../../services/project.service";

interface DefaultProps extends HTMLAttributes<any> {
  project: Project;
  accessCode?: string;
}
function ProjectActions(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const role = auth.user?.role ? `${auth.user?.role}.` : `buyer.`;
  const { project, accessCode, ...viewProps } = props;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isSellerSendOffer, setIsSellerSendOffer] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [resetAdaptLoading, setResetAdaptLoading] = useState(false);
  const [noPart, setNoPart] = useState(true);
  const [canSendOffer, setCanSendOffer] = useState(false);

  const toastSuccess = (project: Project) => {
    api.success({
      message: `Update!`,
      description: t(`project.status.${role}${project.status}`),
      placement: "topRight",
    });
  };

  useEffect(() => {
    const isNoPart: boolean =
      (project.parts && project.parts.every((p) => !p.approved)) || false;
    setNoPart(isNoPart);
    if (project.parts && project.parts.length > 0) {
      const canSendOffer = project.parts.every(
        (p) => p.unitPrice || p.adaptedUnitPrice
      );
      setCanSendOffer(canSendOffer);
    }
  }, [project]);

  const onSendOfferSubmit = async () => {
    setLoading(true);
    try {
      const rs = await projectServices.sendOffer(id);
      dispatch(projectActions.setProject(rs));
      setIsSellerSendOffer(false);
      toastSuccess(rs);
    } catch (error) {
      api.error({
        message: `Send Offer`,
        description: <>Failed!</>,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const onRejectOfferSubmit = async () => {
    setLoading(true);
    try {
      let rs: any;
      if (!accessCode) {
        rs = await projectServices.rejectOffer(id);
      } else {
        rs = await projectServices.rejectOfferByAccessCode(accessCode);
      }
      dispatch(projectActions.setProject(rs));
      onHideModal();
      toastSuccess(rs);
    } catch (error) {
      api.error({
        message: `Reject Offer`,
        description: <>Failed!</>,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const onAcceptSubmit = async () => {
    setLoading(true);
    try {
      let rs: any;
      if (!accessCode) {
        rs = await projectServices.acceptOffer(id);
      } else {
        rs = await projectServices.acceptOfferByAccessCode(accessCode);
      }
      dispatch(projectActions.setProject(rs));
      onHideModal();
      toastSuccess(rs);
    } catch (error) {
      api.error({
        message: `Accept Offer`,
        description: <>Failed!</>,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const onSendOffer = () => {
    setIsSellerSendOffer(true);
  };
  const onAccept = () => {
    setIsAccept(true);
  };
  const onReject = () => {
    setIsReject(true);
  };
  const onHideModal = () => {
    setIsSellerSendOffer(false);
    setIsReject(false);
    setIsAccept(false);
  };

  const onResetOriginal = async () => {
    setResetAdaptLoading(true);
    try {
      const rs = await projectServices.resetAdapt(project.id);
      dispatch(projectActions.setProject(rs));
      api.success({
        message: `${t("success")}!`,
        description: <>Reset adapt!</>,
        placement: "topRight",
      });
    } catch (error) {}
    setResetAdaptLoading(false);
  };

  return (
    <>
      {contextHolder}
      <div {...viewProps} className={`${props.className || ""} d-flex flex-column flex-md-row gap-3`}>
        {auth?.user?.role === UserRole.SELLER && (
          <>
            {(project.status === ProjectStatus.CHECKOUTED ||
              project.status === ProjectStatus.REQUESTED_OFFER) && (
              <>
                <Button onClick={onReject} icon={<CloseOutlined />} danger>
                  {t("project.btn.rejectOffer")}
                </Button>
                {auth.user?.role === UserRole.SELLER &&
                  project.adapted &&
                  project.status === ProjectStatus.CHECKOUTED && (
                    <Button
                      className="ms-auto"
                      type="default"
                      onClick={onResetOriginal}
                      loading={resetAdaptLoading}
                      icon={<UndoOutlined />}
                    >
                      {t("resetAdapt")}
                    </Button>
                  )}
                {!noPart &&
                  (project.status === ProjectStatus.CHECKOUTED ||
                    (project.status === ProjectStatus.REQUESTED_OFFER &&
                      (project.adapted || canSendOffer))) && (
                    <Button type="primary" onClick={onSendOffer}>
                      {project.adapted
                        ? t("project.btn.sendAdaptedOffer")
                        : project.selfCalculation
                        ? t("project.btn.sendOffer")
                        : t("project.btn.acceptAndSendOffer")}{" "}
                      <SendOutlined />
                    </Button>
                  )}
              </>
            )}
            {project.status === ProjectStatus.REQUESTED_OFFER && <></>}
          </>
        )}
        {(auth?.user?.role === UserRole.BUYER || (project && accessCode)) && (
          <>
            {project.status === ProjectStatus.PRICE_ADAPTED && (
              <>
                <Button onClick={onReject} icon={<CloseOutlined />} danger>
                  {t("project.btn.rejectOffer")}
                </Button>
                {!noPart && (
                  <Button
                    type="primary"
                    onClick={onAccept}
                    icon={<CheckOutlined />}
                  >
                    {t("project.btn.acceptOffer")}
                  </Button>
                )}
              </>
            )}
          </>
        )}
        <Modal
          open={isSellerSendOffer}
          title={t("popup.adaptOffer.title")}
          onCancel={onHideModal}
          onOk={onSendOfferSubmit}
          okButtonProps={{
            loading,
          }}
          cancelButtonProps={{
            disabled: loading,
          }}
        >
          <p>
            {project.adapted
              ? t("popup.adaptOffer.message")
              : t("popup.sendOffer.message")}
          </p>
        </Modal>
        <Modal
          open={isReject}
          title={t("popup.rejectOffer.title")}
          onCancel={onHideModal}
          onOk={onRejectOfferSubmit}
          okButtonProps={{
            loading,
          }}
          cancelButtonProps={{
            disabled: loading,
          }}
        >
          <p>{t("popup.rejectOffer.message")}</p>
        </Modal>
        <Modal
          open={isAccept}
          title={t("popup.acceptOffer.title")}
          onCancel={onHideModal}
          onOk={onAcceptSubmit}
          okButtonProps={{
            loading,
          }}
          cancelButtonProps={{
            disabled: loading,
          }}
        >
          <p>{t("popup.acceptOffer.message")}</p>
        </Modal>
      </div>
    </>
  );
}

export default ProjectActions;
